<template>
  <product title="Amazfit 四面弹皮肤风衣"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="199"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/VvBjKh"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'coat',
  components: {
    Product
  },
  data () {
    return {
      slogan: '防晒防风 | 弹力面料 | 超轻舒适 | 便携收纳',
      colors: [
        '黑色',
        '水手蓝',
        '科技灰'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/coat/swiper/1.png',
          CDN_URL + '/images/product/coat/swiper/2.png',
          CDN_URL + '/images/product/coat/swiper/3.png'
        ],
        [
          CDN_URL + '/images/product/coat/swiper/4.png',
          CDN_URL + '/images/product/coat/swiper/5.png',
          CDN_URL + '/images/product/coat/swiper/6.png'
        ],
        [
          CDN_URL + '/images/product/coat/swiper/7.png',
          CDN_URL + '/images/product/coat/swiper/8.png',
          CDN_URL + '/images/product/coat/swiper/9.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/coat/detail/01.jpg',
        CDN_URL + '/images/product/coat/detail/02.jpg',
        CDN_URL + '/images/product/coat/detail/03.jpg',
        CDN_URL + '/images/product/coat/detail/04.jpg',
        CDN_URL + '/images/product/coat/detail/05.jpg',
        CDN_URL + '/images/product/coat/detail/06.jpg',
        CDN_URL + '/images/product/coat/detail/07.jpg',
        CDN_URL + '/images/product/coat/detail/08.jpg',
        CDN_URL + '/images/product/coat/detail/09.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/coat/detail/10.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
